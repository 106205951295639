<template>

    <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;width:80%;margin:20px auto;padding:0 20px; min-width:fit-content">

        <a-form-model ref="ruleForm" :model="FormModel" layout="horizontal" :label-col="labelCol" :wrapper-col="wrapperCol">

            <h4 style="border-bottom:1px solid #eee;padding:15px 0;margin:20px 0">上传视频</h4>
            <a-form-model-item has-feedback label="标题 ">
                <a-input placeholder="视频标题" type="text" style="width:600px" v-model="FormModel.fileTitle" @change="titlechange"></a-input>
            </a-form-model-item>
            <a-form-model-item has-feedback prop="ExVideo" label="上传视频 :">
                <a-upload name="video"
                          list-type="picture-card"
                          :fileList="FormModel.videofileList"
                          @change="videoChange"
                          accept="wmv,.asf,.asx,.rm,.rmvb,.mp4,.3gp,.mov,.m4v,.avi,.dat,.mkv,.flv,.vob"
                          :beforeUpload="beforeUpload">
                    <div v-if="FormModel.videofileList.length < 1">
                        <a-icon type="plus" />
                        <div class="ant-upload-text">
                            上传视频
                        </div>
                    </div>
                </a-upload>
            </a-form-model-item>
            <a-form-model-item has-feedback prop="ExImage" label="上传封面 :">
                <a-upload name="image"
                          :data="ParamData"
                          list-type="picture-card"
                          action="/FileServer/Upload"
                          :fileList="FormModel.fileList"
                          accept=".jpg,.png,.gif,.bmp"
                          @change="imgChange">
                    <div v-if="FormModel.fileList.length < 1">
                        <a-icon type="plus" />
                        <div class="ant-upload-text">

                            上传封面
                        </div>
                    </div>
                </a-upload>
            </a-form-model-item>
            <a-form-item label="上传进度">
                <a-row type="flex" justify="start">
                    <a-col :span="20"> <a-progress :percent=Percentage /></a-col>
                </a-row>
            </a-form-item>
            <a-form-item>
                <a-row>
                    <a-col :span="4" offset="3">
                        <a-button type="primary" block="true" size="large" @click="submitForm('ruleForm')">
                            提交
                        </a-button>
                    </a-col>
                </a-row>
            </a-form-item>

        </a-form-model>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import md5 from "js-md5";
    export default {
        name: "MarketVideo_Detail",
        data() {
            return {
                FormModel: {
                    fileList: [],
                    videofileList: [],
                    fileTitle: '',
                },
                labelCol: { span: 2 },
                wrapperCol: { span: 14 },
                ParamData: {
                    ProcessorName: "Image",
                    JsonDataParams: ""
                },
                VideoParamData: {
                    MD5: "0ca175b9c0f726a831d895e269332461",
                    Suffix: "zip"
                },
                ID: 0,
                chunkSize: 2 * 1024 * 1024,
                Percentage: 0,

            };
        },
        props: {//组件属性
        },
        methods: {//方法

            Titlechange: function (info) {
                var self = this;
                self.FormModel.fileTitle = info.Title;
            },
            imgChange: function (info) {
                var self = this;
                if (info.file.status === 'error') {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    }
                    else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                    info.fileList.pop();
                }
                self.FormModel.fileList = info.fileList;
            },
            videoChange: function (info) {
                var self = this;
                self.Percentage = 0;
                self.FormModel.videofileList = info.fileList;
            },

            submitForm(formName) {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        var self = this;
                        if (self.checkImage() && self.checkVideo() && self.checkTitle()) {
                            var op = {
                                url: "/MarketingModule/MarketVideo/EditMarketVideo",
                                data: {
                                    ID: self.ID,
                                    Title: self.FormModel.fileTitle,
                                    Video: self.FormModel.videofileList.length > 0 ? self.FormModel.videofileList[0].response : "",
                                    Image: self.FormModel.fileList.length > 0 ? self.FormModel.fileList[0].response : "",
                                },
                                OnSuccess: function (data) {
                                    if (data.data) {
                                        self.$message.success('保存成功', 1.5, function () {
                                            self.$router.push({
                                                name: "MarketVideo_List"
                                            })
                                        });
                                    }
                                }
                            };
                            http.Post(op);
                        }
                    } else {
                        return false;
                    }
                });
            },

            checkTitle: function () {
                var rt = true;
                var self = this;
                if (self.FormModel.fileTitle != null) {
                    if (self.FormModel.fileTitle.length == 0) {
                        rt = false;
                        self.$message.error("请填写标题！");
                    }
                } else {
                    rt = false;
                    self.$message.error("请填写标题！");
                }
                return rt;
            },

            checkImage: function () {
                var rt = true;
                var self = this;
                if (self.FormModel.fileList != null) {
                    if (self.FormModel.fileList.length == 0) {
                        rt = false;
                        self.$message.error("请上传图片！");
                    }
                } else {
                    rt = false;
                    self.$message.error("请上传图片！");
                }
                return rt;
            },
            checkVideo: function () {
                var rt = true;
                var self = this;
                if (self.FormModel.videofileList != null) {
                    if (self.FormModel.videofileList.length == 0) {
                        rt = false;
                        self.$message.error("请上传视频！");
                    }
                } else {
                    rt = false;
                    self.$message.error("请上传视频！");
                }
                return rt;
            },
            beforeUpload(file) {
                var self = this;
                self.Percentage = 0;
                file.status = "uploading";
                var md5 = self.getFileMD5(file);
                var Suffix = file.name.substring(file.name.indexOf('.') + 1).toLowerCase();
                var fileChunkList = this.createFileChunk(file, self.chunkSize);
                self.Upload(0, fileChunkList, Suffix, md5);
                return false;
            },
            Upload(i, fileChunkList, Suffix, md5) {
                var self = this;
                let FormDatas = new FormData();
                if (i != 0) {
                    FormDatas.append('file', fileChunkList[i - 1].file);
                    FormDatas.append('Suffix', Suffix);
                }
                FormDatas.append('MD5', md5);
                var op = {
                    url: "/MarketingModule/MarketVideo/Upload",
                    data: FormDatas,
                    ContentType: 'multipart/form-data',
                    OnSuccess: function (data) {
                        i = data.data.Progress;
                        self.Percentage = parseFloat(data.data.Progress * 100 / fileChunkList.length).toFixed(2) * 1;
                        if (data.data.Progress < fileChunkList.length) {
                            self.Upload(data.data.Progress + 1, fileChunkList, Suffix, md5)
                        } else {
                            self.$message.success('上传成功', 1.5, function () {
                                self.FormModel.videofileList.forEach(item => {
                                    item.status = "done";
                                    item.url = data.data.Path;
                                    item.name = data.data.Path;
                                    item.response = data.data.Path;
                                    item.uid = "0";
                                })
                            });
                        }
                    }
                };
                http.Post(op);
            },
            getFileMD5(file) {
                return md5(file.name + file.size + file.lastModifiedDate);
            },
            createFileChunk(file, size) {
                var fileChunkList = [];
                let count = 0;
                while (count < file.size) {
                    fileChunkList.push({
                        file: file.slice(count, count + size)
                    });
                    count += size;
                }
                return fileChunkList;
            },
            getData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/MarketVideo/GetMarketVideoInfo",
                    data: {
                        ID: self.ID,
                    },
                    OnSuccess: function (data) {
                        self.FormModel.fileTitle = data.data.Title;
                        var Img = data.data.Image;
                        if (Img != "" && Img != null) {
                            self.FormModel.fileList = [
                                {
                                    name: Img,
                                    response: Img,
                                    status: "done",
                                    uid: "0",
                                    url: Img,
                                },
                            ];
                        } else {
                            self.FormModel.fileList = [];
                        }
                        var V = data.data.Video;
                        if (V != "" && V != null) {
                            self.FormModel.videofileList = [
                                {
                                    name: V,
                                    response: V,
                                    status: "done",
                                    uid: "0",
                                    url: V,
                                },
                            ];
                            self.Percentage = 100;
                        } else {
                            self.FormModel.videofileList = [];
                        }
                    }
                };
                http.Post(op);
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            var self = this;
            self.ID = this.$route.query.ID;
            self.getData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>
